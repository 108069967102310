// Lighten up the font weight in pagination info
.er-pagination-info b {
  font-weight: 500;
}

.trimmable {
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Table that has trimmable cells
.table-trimmable {
  th,
  td {
    @extend .trimmable;
  }
}

// Style our table-like data views (e.g. source and target location index pages)
.er-data-row {
  &.selected,
  &.selected:hover {
    background: $gray-200;
  }

  &:hover {
    background: $gray-100;
  }
}

.status-row {
  &.success,
  &.success td {
    background-color: mix($success, $card-alert-bg, 5%);
  }

  &.warning,
  &.warning td {
    background-color: mix($warning, $card-alert-bg, 5%);
  }
}

.table-hover {
  .status-row.success:hover,
  .status-row.success:hover td {
    background-color: mix($success, $card-alert-bg, 15%);
  }

  .status-row.warning:hover,
  .status-row.warning:hover td {
    background-color: mix($warning, $card-alert-bg, 15%);
  }
}

.site-announcement-link {
  text-decoration: $alert-link-text-decoration;
}

// Color variants
//
// Using Bootstrap's core alert-variant mixin to generate solid background color + yiq colorized text (and making close/links match those colors)

@each $color, $value in $theme-colors {
  .site-announcement-#{$color} {
    @include alert-variant(
      theme-color-level($color, $alert-bg-level),
      theme-color-level($color, $alert-border-level),
      color-yiq(theme-color-level($color, $alert-bg-level))
    );

    .site-announcement-link {
      color: color-yiq(theme-color-level($color, $alert-bg-level));
    }
  }
}

@include media-breakpoint-down(md) {
  .pagination {
    .page-item.inactive {
      display: none;
    }
  }
}

.bg-branded {
  position: relative;
  overflow: hidden;

  .bg-background {
    background-color: $primary;
    background-image: url(../../images/backgrounds/bg-branded.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 500px;
    z-index: -1;
  }

  .background__after {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .masked-content-group {
    position: relative;
    z-index: 1;
  }
}

.hover-children {
  &:hover {
    h4 {
      color: $link-color;
      text-decoration: $link-hover-decoration;
    }
  }
}

.sk-wave {
  &.hide {
    display: none;
  }
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: $custom-control-indicator-bg;
}

.form-control-label.disabled {
  color: $custom-control-label-disabled-color;
}

.upgrade-panel {
  @include border-radius($input-border-radius);
  background-color: $urllo-primary-20;
  border: 1px solid $urllo-primary-100;
  padding: 1rem;
  margin-bottom: 1.375rem;

  label {
    color: $custom-control-label-disabled-color;

    &::before {
      background-color: $custom-control-indicator-disabled-bg;
    }
  }

  a.text-accent1 {
    @include font-size($small-font-size);
    font-weight: 500;
  }
}

.text-code {
  font-family: $font-family-monospace;
  @include font-size($small-font-size);
}

#sidenav_new_dropdown {
  background: $urllo-primary-20;

  .dropdown-item > .fas,
  .dropdown-item > .far,
  .dropdown-item > .fal,
  .dropdown-item > .fab {
    min-width: 1.75rem;
  }
}

$blur: 5;
@mixin blur-x {
  @for $i from 1 through $blur {
    .blur-#{$i} {
      filter: blur(#{$i}px);
    }
  }
}

@include blur-x;

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.header-space {
  padding-top: $header-spacing-y;
  padding-bottom: $header-spacing-y;
}
