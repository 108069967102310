/*!
 * Import Dashkit core components that we use in the redirect dashboard
 */
@import '~dashkit/scss/dashkit/mixins';
@import '~dashkit/scss/dashkit/alert';
@import '~dashkit/scss/dashkit/badge';
@import '~dashkit/scss/dashkit/breadcrumb';
@import '~dashkit/scss/dashkit/buttons';
@import '~dashkit/scss/dashkit/card';
@import '~dashkit/scss/dashkit/code';
@import '~dashkit/scss/dashkit/custom-forms';
@import '~dashkit/scss/dashkit/dropdowns';
@import '~dashkit/scss/dashkit/forms';
@import '~dashkit/scss/dashkit/jumbotron';
@import '~dashkit/scss/dashkit/list-group';
@import '~dashkit/scss/dashkit/modal';
@import '~dashkit/scss/dashkit/nav';
@import '~dashkit/scss/dashkit/navbar';
@import '~dashkit/scss/dashkit/popover';
@import '~dashkit/scss/dashkit/progress';
@import '~dashkit/scss/dashkit/reboot';
@import '~dashkit/scss/dashkit/root';
@import '~dashkit/scss/dashkit/tables';
@import '~dashkit/scss/dashkit/toasts';
@import '~dashkit/scss/dashkit/type';
@import '~dashkit/scss/dashkit/utilities';

// Dashkit only components

@import '~dashkit/scss/dashkit/avatar';
@import '~dashkit/scss/dashkit/chart';
@import '~dashkit/scss/dashkit/comment';
@import '~dashkit/scss/dashkit/header';
@import '~dashkit/scss/dashkit/icon';
@import '~dashkit/scss/dashkit/main-content';
@import '~dashkit/scss/dashkit/vendors';
