@include media-breakpoint-up(sm) {
  .surveyquestion {
    font-size: 1.6rem;
  }
}

@include media-breakpoint-up(md) {
  .surveyquestion {
    font-size: 2rem;
  }
}

div.surveyitem-container {
  background-color: $gray-200;
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  justify-content: flex-end;
}

label.selectable {
  display: flex;
  flex: 1;
}

ul.horizontal {
  display: flex;
  gap: 2vw;
}

li.surveyitem,
li.surveyitem-horizontal {
  display: flex;
  flex: 1;
  padding-bottom: 2vh;
}

div.surveyitem-container:hover {
  background-color: $gray-300;
  cursor: pointer;
}

div.surveyitem-content {
  flex: 1;
}

:checked ~ div.surveyitem-content {
  background-color: #d5ebd1;
}

h3.surveyitem-header,
h4.surveyitem-value-bold {
  display: inline;
}

.checkmark,
.radio-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.checkmark:hover,
.radio-button:hover {
  background-color: $gray-300;
  cursor: pointer;
}

.checkmark:checked,
.radio-button:checked {
  background-color: #d5ebd1;
}

.checkmark:checked:before,
.radio-button:checked:before {
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  font-size: 1.6em;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-right: 0.4vw;
  z-index: 10;
}

.radio-button-small {
  @extend .radio-button;
  font-size: 0.8em;
}
