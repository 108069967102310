@page {
  size: auto;
  margin: auto;
}

@media print {
  body,
  #er-main-content {
    height: auto;
  }

  #er-main-sidenav,
  #er-main-topnav {
    display: none !important;
  }

  #er-main-content {
    margin-left: 0 !important;
  }

  .header-pretitle {
    display: none !important;
  }
}
