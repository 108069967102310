.single-url-input {
  min-width: 0;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;

  .badge {
    font-size: 50%;
    line-height: 1.75;
  }

  .badge-secondary {
    color: #666;
    background-color: $urllo-gray-100;
    margin: 2px;
  }
}

[contenteditable]:empty:before {
  content: attr(data-placeholder);
  color: grey;
}
