@import 'node_modules/@yaireo/tagify/src/tagify';

.tagify__tag {
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  min-width: 40px;
}

.tagify__input {
  font-size: 100%;
  font-weight: $badge-font-weight;
  height: 2em;
  margin: 0;
  padding: 0.4rem 1rem;
  overflow: visible;
}

.tagify--inside {
  --tag-bg: #{$urllo-primary-200};
  --tag-text-color: #{$gray-800};
  --tags-border-color: #{$gray-200};
  --tag-border-radius: 20px;
  --tag-remove-bg: var(--tag-bg);
  --tag-pad: 0.4em 1em;
  --tag-inset-shadow-size: 5em;
  --tag-remove-btn-color: #{$gray-400};
  --tag-remove-btn-bg--hover: #{$urllo-primary-800};
  --readonly-striped: 0;
  --placeholder-color: #{$input-placeholder-color};
  --tag--min-width: 3ch;
  --tagify-dd-item-pad: 4em;
  --tag-hover: #{$urllo-primary-400};

  border: none;
}

.tagify--inside {
  &.readonly {
    border: none;
    --tag-hover: #{$urllo-primary-400};

    &.selectable {
      --tag-hover: var(--placeholder-color-focus);
    }
  }
  &.format-large {
    border: 1px solid #c4c4c4;
    border-radius: 0.375rem;
    font-size: inherit;
    font-weight: inherit;
    height: auto;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

// Always show placeholder, except when some text is entered.
.tagify__tag + .tagify__input::before {
  align-content: center;
  flex: 1;
  height: 1.8em;
  opacity: 1;
}

.tagify__tag + .tagify__input {
  &:not(:empty) {
    --placeholder-color: transparent;
    --placeholder-color-focus: transparent;
  }
}

.tagify__dropdown {
  max-width: 300px;
  min-width: 200px;
  overflow: auto;
}

.tagify__dropdown__wrapper {
  border: 1px solid #{$gray-300};
  border-radius: 5px;
  box-shadow: 1px 1px #{$gray-300};
}

.tags-selectable .tagify__dropdown__item {
  background: #{$urllo-primary-200};
  border: 1px solid #{$gray-200};
  border-radius: 20px;
  color: #{$gray-800};
  display: inline-flex;
  font-size: $badge-font-size;
  justify-content: center;
  padding: 0.4em 1em;
  margin: 0.4em;
  min-width: 5em;
  transition: 0s;
}

.tags-selectable .tagify__dropdown__item--active {
  color: black;
}

.tags-selectable .tagify__dropdown__item:hover {
  background: #{$urllo-primary-400};
  border-color: #{$gray-200};
}

tag.readonly {
  --tag--min-width: 2rem;
  --tag-text-color: #{$gray-800};
  --tags-border-color: #{$gray-200};
  --tag-border-radius: #{$btn-border-radius-sm};
}

span.tag-text {
  font-weight: 400;
}

div.tag-modal-filter {
  max-height: 60px;
}

div.tag-modal-width {
  max-width: 600px;
}

div.tag-modal-height {
  height: 600px;
}
