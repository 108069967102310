// account_history
.account-history-row .account-history-icon {
  background-color: $urllo-gray-50;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 0.75rem;
}

td.account-history-action-item {
  max-width: 500px;
}

.show-entity div {
  margin-left: auto;
  text-align: right;
}

.account-history-detail-value {
  .badge {
    margin-top: -0.3em;
  }
}

.account-history-badge-added {
  background-color: lighten($success, 30%);
  color: $gray-800;
}

.account-history-badge-removed {
  background-color: lighten($danger, 40%);
  color: $urllo-gray-800;
}

.account-history-row .account-history-description {
  max-width: 500px;
}

.account-history-row td.whodunnit {
  min-width: 200px;
}

.account-history-icon {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;

  &.destroy:before {
    content: '\f2ed';
  }
  &.create:before {
    content: '\f067';
  }
  &.update:before {
    content: '\f303';
  }
}

.history-feed-event {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;

  &.destroy:before {
    content: '\f2ed';
  }
  &.create:before {
    content: '\f067';
  }
  &.update:before {
    content: '\f303';
  }
}

.history-feed-details {
  max-width: 300px;
}

.account-history-event-create:before {
  content: '\f890';
}

.account-history-event-update:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: '\f303';
}

.account-history-event-destroy:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: '\f2ed';
}

.account-history-pagination-avatar {
  @extend .avatar;
  @extend .avatar-xs;
  left: 7px;
}

.account-history-pagination-avatar-title {
  @extend .avatar-title;
  background-color: #fff;
  font-size: 14px;
  border: 1px solid $urllo-gray-100;
  border-radius: 8px;
  color: $urllo-gray-800;

  &:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    content: '\f0dc';
  }
}

.account-history-pagination {
  background-color: #fff;
}

.list-group-activity .list-group-item:not(:last-child):before {
  border-left: 1px solid #e3ebf6;
  content: '';
  height: 100%;
  left: 2.75rem;
  position: absolute;
  top: 0;
}

// last child set to the same as the rest for now
// change this if you don't want the line to run all
// the way to the bottom.
.list-group-activity .list-group-item:is(:last-child):before {
  border-left: 1px solid #e3ebf6;
  content: '';
  height: 100%;
  left: 2.75rem;
  position: absolute;
  top: 0;
}

.list-group-activity .list-group-item {
  &.active {
    color: $list-group-action-color;
    background-color: $urllo-gray-50;
  }

  border: 0;
}

.list-group-detail .list-group-item {
  border: 0;
}

//-- account_history
.account-history-header {
  @extend .font-weight-bold;
}

.audit-log-identifier {
  @extend .font-weight-bold;
}

.audit-tags {
  span + span {
    margin-left: 4px;
  }

  .badge {
    font-size: 0.86em;
  }
}

.audit-tag a {
  color: #3e4a5a;
}

ul.autocomplete-results {
  &:empty {
    border: 0;
  }

  border: 1px solid $urllo-gray-100;
  border-radius: 19px;

  & li {
    border: 0;
  }

  & li:not([disabled]) {
    cursor: pointer;

    &:not(.active):hover {
      background-color: $urllo-gray-50;
    }
  }

  & .list-group-item:first-child {
    border-radius: 19px 19px 0 0;
  }

  & .list-group-item:last-child {
    border-radius: 0 0 19px 19px;
  }

  & .list-group-item:only-child {
    border-radius: 19px;
  }
}

.autocomplete-active {
  border: 1px solid $primary;
  border-radius: 19px;
}

.autocomplete-result-wrapper {
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  left: 0;
  top: 40px;
  border-radius: 19px;
}

@include media-breakpoint-up(lg) {
  .card-adjust-lg {
    height: 0;
    min-height: 100%;
  }

  .card-adjust-lg .card {
    height: calc(100% - 1.5rem);
  }

  .card-adjust-lg .card-body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}

div[data-clickable-row-href-param] {
  cursor: pointer;
}

.data-row-highlighted {
  background-color: $data-row-highlight;

  &:hover {
    background-color: $data-row-highlight !important;
  }
}
