@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleHair.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleHair.woff') format('woff');
  font-weight: 50;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleHairlineItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleHairlineItalic.woff') format('woff');
  font-weight: 50;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleThin.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleThinItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleUltraLight.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleUltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleUltraLightItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleUltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleLight.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleLightItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleLightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBook.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBook.woff') format('woff');
  font-weight: 350;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBookItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBookItalic.woff') format('woff');
  font-weight: 350;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleRegular.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleRegularItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleRegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleMedium.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleMediumItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleMediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleSemiBold.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleSemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleSemiBoldItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleSemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBold.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBoldItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBlack.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBlack.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBlackItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleBlackItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleHeavy.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleHeavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biennale';
  src: url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleHeavyItalic.woff2') format('woff2'),
    url('https://www.urllo-cdn.com/fonts/biennale/1.0.0/BiennaleHeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
