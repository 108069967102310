// If there is no header row, do not include
// top border for cells of first row
.card-table-no-header {
  > tbody:first-child {
    > tr:first-child {
      > td {
        border-top-width: 0;
      }
    }
  }
}
