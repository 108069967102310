:root.api-docs {
  @import 'biennale.scss';
}

.api-docs {
  body {
    padding: 0;
    margin: 0;
  }
  .menu-content {
    > :first-child {
      padding: 20px 50px 20px 20px;
    }
    > div:last-child > div {
      display: none;
    }
  }
  .react-tabs__tab-panel > div > div:first-child > span {
    display: none;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-base !important;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  h1,
  h2 {
    color: $black;
  }
  h1 {
    line-height: 1.6;
  }
  h3 {
    font-family: $font-family-base !important;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 1em;
    color: $urllo-gray-600;
  }
  span {
    color: $urllo-gray-500;

    &.http-verb,
    &.operation-type {
      color: $white;
    }
  }
  pre {
    color: $urllo-gray-100;
  }
  [role='navigation'] {
    [role='menuitem'] {
      font-family: $font-family-base !important;
      font-weight: 400;
    }
  }
  .redoc {
    &-json {
      white-space: nowrap;
    }
    &-markdown {
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $urllo-gray-500;
      }
      h5 {
        font-size: 1.25rem;
      }
    }
  }
  .token {
    &.function {
      color: #e6db74;
    }
    &.string-property.property {
      color: $white;
    }
  }
}
