@mixin validation-state-focus($color) {
  $shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 6px lighten($color, 20%);

  .select2-dropdown,
  .select2-selection {
    border-color: $color !important;
  }

  .select2-container--focus .select2-selection,
  .select2-container--open .select2-selection {
    //@include box-shadow($shadow);
    //border-color: $color !important;
    border-color: $color !important;
    box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25) !important;
  }

  &.select2-drop-active {
    border-color: $color !important;

    &.select2-drop.select2-drop-above {
      border-top-color: $color !important;
    }
  }
}

.form-group-invalid {
  @include validation-state-focus($danger);
}

// Improve the colours used on the select2 options
.select2-results__option {
  background-color: $white !important;
  color: $dropdown-link-color !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $dropdown-link-hover-bg !important;
  color: $dropdown-link-hover-color !important;
}

.select2-container--default .select2-results__option[aria-selected='true'] {
  background-color: $dropdown-link-active-bg !important;
  color: $dropdown-link-active-color !important;
  font-weight: 500;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: $gray-200;
}

.select2-container--default.select2-container--disabled .select2-selection--single:focus,
.select2-container--default.select2-container--focus .select2-selection--multiple:focus {
  background-color: $gray-200;
  border: $input-border-width solid $input-border-color;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  display: inline;
  height: 36px;
}
