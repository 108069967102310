/*!
 * This is where we override the variables defined by Bootstrap and/or Dashkit
 */

//
// Urllo brand typefaces
//
$font-family-base: 'Biennale', 'Century Gothic';

/*
 * See `html: { font-size: 14px; }` in _bootstrap_dashkit_custom.scss
 *
 * Typically the default root font-size is 16px but our dashkit theme overrides this to be 15px.
 * Due to the nature of our new font Biennale, I scaled this back to 14px. This is because Biennale is a larger-bolder
 * typeface and anything more than 14px was awkward to work with in our theme.
 *
 * Note: 1rem would now be equivalent to 14px with this change.
 */
$font-size-base: 1rem !default;
$h1-font-size: 1.857rem !default;
$h2-font-size: 1.429rem !default;
$h3-font-size: 1.214rem !default;
$h4-font-size: 1.071rem !default;
$h5-font-size: 0.929rem !default;
$h6-font-size: 0.714rem !default;
$small-font-size: 0.929rem !default;
$badge-font-size: 0.86rem !default;
$badge-font-weight: 400 !default;
$headings-font-weight: 500 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$line-height-base: 1.5 !default;
$headings-letter-spacing: 0;
$display-letter-spacing: 0;

//
// Urllo brand colours
// Only used directly below for definign Urllo Colour System and Dashkit Color System.
//
// ** Do not use these outside of this file **
//
$urllo-purple: #5922cb;
$urllo-deep-purple: #432c77;
$urllo-highlight-lime: #b8ec00;
$urllo-teal: #2dd1b2;
$urllo-duck-egg: #9ecdbe;
$urllo-warm-black: #241f20;

//
// Urllo Colour System
// This is our naming-convention/colour-framework for defining colours across all products
//
$urllo-white: #ffffff;
$urllo-black: $urllo-warm-black;

$urllo-primary: $urllo-purple;
$urllo-secondary: $urllo-deep-purple;

$urllo-accent1: $urllo-highlight-lime;
$urllo-accent2: $urllo-teal;
$urllo-accent3: $urllo-duck-egg;

$urllo-background: $urllo-white;

$urllo-info: $urllo-duck-egg;
$urllo-success: $urllo-teal;
$urllo-warning: #ffe401;
$urllo-danger: #e81a25;

$urllo-gray-900: #393435;
$urllo-gray-800: #4f4b4c;
$urllo-gray-750: #5b5758;
$urllo-gray-700: #656162;
$urllo-gray-600: #7b7879;
$urllo-gray-500: #918e8f;
$urllo-gray-400: #a7a5a5;
$urllo-gray-300: #bcbbbb;
$urllo-gray-200: #d3d2d2;
$urllo-gray-100: #e8e8e8;
$urllo-gray-50: #f3f3f3;

$urllo-primary-900: #6937d0;
$urllo-primary-800: #7a4ed5;
$urllo-primary-700: #8a63da;
$urllo-primary-600: #9b7ae0;
$urllo-primary-500: #ab90e5;
$urllo-primary-400: #bca6ea;
$urllo-primary-300: #cdbcef;
$urllo-primary-200: #ded2f5;
$urllo-primary-100: #eee8fa;
$urllo-primary-50: #f6f3fd;
$urllo-primary-20: #fcfafe;

//
// Dashkit Color System
// These are overrides found in vendor/dashkit/scss/_variables.scss
//
$white: $urllo-white;
$gray-100: $urllo-gray-50;
$gray-200: $urllo-gray-200;
$gray-300: $urllo-gray-300;
$gray-400: $urllo-gray-400;
$gray-500: $urllo-gray-500;
$gray-600: $urllo-gray-600;
$gray-700: $urllo-gray-700;
$gray-800: $urllo-gray-800;
$gray-900: $urllo-gray-900;
$black: $urllo-black;

$primary: $urllo-primary;
$secondary: $urllo-secondary;
$success: $urllo-success;
$info: $urllo-info;
$warning: $urllo-warning;
$danger: $urllo-danger;
$light: $urllo-gray-100 !default;
$dark: $urllo-black !default;

// Social service colours
$twitter-color: #55acee;
$facebook-color: #3b5998;
$linkedin-color: #0077b5;
$youtube-color: #ff0000;

// Progress bar colours
$progress-red: $danger;
$progress-green: $urllo-accent2;
$progress-orange: #ffa800;
$progress-blue: #2d9ed1;

// Analytics colours
$analytics-chart-segment-highlight: #ba2891;
$analytics-data-row-highlight: lighten($analytics-chart-segment-highlight, 51%);

// Generic row highlight
$data-row-highlight: lighten($analytics-chart-segment-highlight, 51%);

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'white': $white,
    'accent1': $urllo-accent1,
    'accent2': $urllo-accent2,
    'accent3': $urllo-accent3,
    'progress-red': $progress-red,
    'progress-green': $progress-green,
    'progress-orange': $progress-orange,
    'progress-blue': $progress-blue,
    'light-purple': $urllo-primary-20,
    'twitter': $twitter-color,
    'facebook': $facebook-color,
    'linkedin': $linkedin-color,
  ),
  $theme-colors
);

$body-bg: $urllo-gray-50;
$body-color: $urllo-gray-800;
$headings-color: $black;
$pagination-color: $urllo-gray-800;
$input-color: $black;
$input-disabled-bg: $urllo-gray-100;

$link-color: $urllo-primary;
$link-hover-color: $urllo-primary;
// TODO: TECH-700 - Do we want to underline links on hover? Design docs suggest we should.
// $link-hover-decoration: underline;

// Override some Dashkit colours
$auth-bg: $urllo-gray-50;

// Increase the width of the navbar icons to make them work better with Font Awesome
$navbar-icon-min-width: 2.25rem;

// Dashboard styles in Navbar
$navbar-light-heading-color: $primary;
$navbar-light-active-color: $black;

// Style border radius like the brand site
//$border-radius: .25rem;
//$border-radius-lg: .3rem;
//$border-radius-sm: .2rem;
$card-border-radius: 20px;
$alert-border-radius: 20px;

// Alert colours
//$alert-bg-level: -10;
//$alert-border-level: -9;
//$alert-color-level: 6 !default;

// Buttons
$btn-border-radius: 1.5rem;
$btn-border-radius-lg: 2rem;
$btn-border-radius-sm: 1rem;

$input-btn-padding-y: 0.4rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y-sm: 0.15rem;
$input-btn-padding-x-sm: 0.7rem;
$input-btn-padding-y-lg: 0.6rem;
$input-btn-padding-x-lg: 1.5rem;

// Card colours
$card-alert-bg: mix($light, $white, 10%);
$card-alert-border: mix($light, $white, 90%);

$card-primary-text: $white;
$card-primary-border: $primary;
$card-primary-heading-bg: $primary;

$card-success-text: $link-color;
$card-success-border: mix($success, $card-alert-border, 5%);
$card-success-heading-bg: mix($success, $card-alert-bg, 5%);

$card-info-text: $info;
$card-info-border: mix($gray-400, $card-alert-border, 2%);
$card-info-heading-bg: mix($gray-400, $card-alert-bg, 2%);

$card-warning-text: $warning;
$card-warning-border: mix($warning, $card-alert-border, 3%);
$card-warning-heading-bg: mix($warning, $card-alert-bg, 3%);

$card-danger-text: $danger;
$card-danger-border: mix($danger, $card-alert-border, 2.5%);
$card-danger-heading-bg: mix($danger, $card-alert-bg, 2.5%);

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-border-color: rgba($black, 0.1);

$dropdown-link-color: $gray-700;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;

$dropdown-link-active-color: $black;
$dropdown-link-active-bg: transparent;

// Paths
$path-to-img: 'img';

// SpinKit variables
$spinkit-spinner-margin: 40px auto;
$spinkit-size: 40px;
$spinkit-spinner-color: rgba($gray-500, 0.5);

// Popovers
$popover-max-width: 13rem;
$popover-header-margin-bottom: 0.3rem;
