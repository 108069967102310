/*!
 * Import Dashkit components that we use in the dashboard
 */

// Import type faces
@import 'biennale';

// Bootstrap functions
@import '~bootstrap/scss/functions';

// Custom variables
@import 'easyredir_variables';

// Dashkit variables
@import '~dashkit/scss/dashkit/variables';

// Bootstrap core
@import 'bootstrap_core';

// Select2 improvements for Bootstrap
@import 'select2_improvements';

// Dashkit core
@import 'dashkit_core';

// Customizations of Bootstrap and Dashkit styles
@import 'bootstrap_dashkit_custom';

// Our custom styles
@import 'api_docs';
@import 'easyredir_global';
@import 'easyredir_bulk_actions';
@import 'easyredir_subscriptions';
@import 'easyredir_two_factor';
@import 'easyredir_analytics';
@import 'easyredir_redirects';
@import 'easyredir_hosts';
@import 'easyredir_audit_log';
@import 'easyredir_card_table';
@import 'easyredir_surveys';

// Font Awesome
@import 'font_awesome';

// Include other base styles
@import 'layout';

// Include overrides for print media
@import 'easyredir_print';

// Include SpinKit
@import '~spinkit/scss/spinners/3-wave';

// Tags
@import 'tags';
