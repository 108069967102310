body.analytics-body {
  height: 100%;
}

.er-analytics-main-content {
  height: calc(100% - 65px); // 100% minus the height of the top nav bar
}

.feature-overlay-container {
  position: relative;
  min-height: 100%;

  .feature-overlay {
    background-color: #000;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .feature-overlay-modal {
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 6;
  }

  .feature-modal-content {
    display: flex;
    justify-content: center;
    margin: 10% 20px;
    min-width: 300px;
    max-width: 500px;
    position: fixed;
  }
}

.analytics-overlay-container {
  position: relative;
  min-height: 100%;

  .analytics-overlay {
    background-color: #000;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .analytics-overlay-modal {
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 6;
  }

  .analytics-modal-content {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    min-width: 300px;
    position: fixed;
  }
}

.daterangepicker .drp-buttons .btn {
  font-weight: 600;
  padding: 4px 12px;
}

.daterangepicker td.active,
.daterangepicker td.in-range.active,
.daterangepicker td.active:hover,
.daterangepicker td.off.end-date.active:hover {
  background-color: $primary;
}

.daterangepicker td.in-range {
  background-color: $urllo-primary-100;
}

.daterangepicker .ranges li.active {
  background-color: $primary;
}

.daterangepicker td.off,
.daterangepicker td.off.active,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
}

.table-analytics {
  tr {
    min-height: 60px;
    height: 60px;
  }

  td {
    vertical-align: middle;
  }

  thead th {
    background-color: $gray-200;
    border-bottom: solid 1px $gray-300;
    border-top: solid 1px $gray-300;
    color: $urllo-gray-800;
  }

  .summary-info {
    line-height: 1.2;
  }

  .summary-divider {
    font-size: 26px;
    font-weight: 100;
    margin: 3px 8px 0;
  }

  .summary-header {
    font-size: 20px;
    font-weight: 300;
    color: $urllo-gray-800;
  }

  .summary-detail {
    font-weight: 400;
    font-size: 11px;
  }

  tr.summary-row {
    td {
      background-color: $urllo-gray-50;
    }
    td:nth-of-type(3) {
      background-color: #fff;
    }
  }

  tr.data-row-highlighted {
    background-color: $analytics-data-row-highlight !important;

    td {
      background-color: $analytics-data-row-highlight !important;
    }
  }

  th:nth-of-type(1),
  td:nth-of-type(1) {
    font-size: $font-size-sm;
    font-weight: 400;
    color: $gray-500;
    width: 1px;
    padding-right: 0;
  }

  // Style the second table column
  th:nth-of-type(2),
  td:nth-of-type(2) {
    border-right: solid 1px $gray-300;
    max-width: 400px;
  }
  td:nth-of-type(2) {
    font-weight: 600;
    font-size: 0.9375rem; // same as h4

    a {
      display: block;
    }
  }

  // Style the second table column
  th:nth-of-type(3),
  td:nth-of-type(3) {
    border-right: solid 1px $gray-300;
    text-align: right;
    min-width: 140px;
    width: 125px;
    max-width: 200px;
  }
  td:nth-of-type(3) {
    border-right: solid 1px $gray-200;
    font-weight: 600;
    background: $urllo-gray-50;
  }

  // Style the 3rd and 4th table columns
  th:nth-of-type(4) {
    border-right: solid 1px $gray-300;
  }

  th:nth-of-type(4),
  td:nth-of-type(4),
  th:nth-of-type(5),
  td:nth-of-type(5) {
    min-width: 200px;
    width: 300px;
    max-width: 400px;
  }

  th.sorted-desc:after {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    content: '\f063';
    margin-left: 8px;
  }

  th.sorted-asc:after {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    content: '\f062';
    margin-left: 8px;
  }
}

.popover-chart {
  max-width: 15rem;

  .popover-body-value {
    font-weight: 600;
    margin-left: 10px;
  }

  // hide chart tooltip rows with no data
  .popover-body-zero {
    display: none !important;
  }

  .popover-footer {
    margin-top: $popover-header-margin-bottom;
    padding-top: $popover-header-margin-bottom;
    border-top: 1px solid $gray-300;
    font-size: 11px;
  }
}

.daterangepicker {
  font-family: $font-family-base;

  .calendar-table th,
  .calendar-table td {
    padding: 4px;
  }
}

.expand-tooltip-container {
  .tooltip,
  .tooltip-inner {
    max-width: 100%;
    white-space: nowrap;
  }
}
