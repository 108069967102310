/*!
 * This is where we override the styles defined by Bootstrap and/or Dashkit
 */

/*
 * See `$font-size-base: 1rem !default;` in _easyredir_variables.scss
 *
 * Typically the default root font-size is 16px but our dashkit theme overrides this to be 15px.
 * Due to the nature of our new font Biennale, I scaled this back to 14px. This is because Biennale is a larger-bolder
 * typeface and anything more than 14px was awkward to work with in our theme.
 *
 * Note: 1rem would now be equivalent to 14px with this change.
 */
html {
  font-size: 14px;
}

h1,
.h1 {
  font-weight: 600;
}

h6,
.h6 {
  font-weight: 700;
}

@mixin card-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;

  & > .card-header {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;

    //+ .card-collapse > .card-body {
    //  border-top-color: $border;
    //}
    .label {
      color: $heading-bg-color;
      background-color: $heading-text-color;
    }
  }
  & > .card-footer {
    color: $heading-text-color;

    //+ .card-collapse > .card-body {
    //  border-bottom-color: $border;
    //}
  }
}

.form-control:disabled {
  background-color: $gray-200;
}

/*!
 * Use FontAwesome instead of Feather for icons around the application
 */
// Navbar icons
.navbar-nav .nav-link > .fas,
.navbar-nav .nav-link > .far,
.navbar-nav .nav-link > .fal,
.navbar-nav .nav-link > .fab {
  min-width: $navbar-icon-min-width; // Note: Test if we actually need this?
  padding-bottom: 0.125em;
  //font-size: $font-size-lg;
  //line-height: ($font-size-base * $line-height-base);
}

// Navbar link icon
.navbar-nav .nav-link > .fas,
.navbar-nav .nav-link > .far,
.navbar-nav .nav-link > .fal,
.navbar-nav .nav-link > .fab {
  display: inline-block;
  min-width: $navbar-icon-min-width;
}

// Dropdown menu icons
.dropdown-menu .dropdown-item > .fas,
.dropdown-menu .dropdown-item > .far,
.dropdown-menu .dropdown-item > .fal,
.dropdown-menu .dropdown-item > .fab {
  min-width: $navbar-icon-min-width; // Note: Test if we actually need this?
  //padding-bottom: .125em;
  //font-size: $font-size-lg;
  //line-height: ($font-size-base * $line-height-base);
}

// Icon
//
// General styles
.icon {
  &.active {
    .fas,
    .far,
    .fal,
    .fab {
      display: block;
      min-width: 1em * $line-height-base;
      min-height: 1em * $line-height-base;
      font-size: $font-size-lg;
      mask-image: url(~dashkit/scss/dashkit/img/masks/icon-status.svg);
      mask-size: 100% 100%;
    }
  }
}

.navbar-vertical {
  .navbar-nav .nav-link[data-toggle='collapse'] {
    &:after {
      content: '\f107';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
    }
  }

  .navbar-heading {
    letter-spacing: 0.07em;
  }
}

// Dropdown toggles
.dropdown-toggle {
  &:after {
    content: '\f107';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
  }
}

.dropup > .dropdown-toggle {
  &:after {
    content: '\f106';
  }
}

.dropright > .dropdown-toggle {
  &:after {
    content: '\f105';
  }
}

.dropleft > .dropdown-toggle {
  &:after {
    content: '\f104';
  }
}

/*!
 * Adjust the navbar brand img to a better size for the brand logomark
 */
.navbar-brand-img,
.navbar-brand > img {
  max-height: 2.5rem;
}

h6.header-pretitle {
  font-size: 0.675rem;
}

label,
legend {
  color: $black;
  font-weight: 500;
}

label.collection_radio_buttons {
  color: $body-color;
  font-weight: 400;
}

// Bootstrap 4 uses .pagination on a ul element, but that includes a bottom margin. Remove it.
.pagination {
  margin-bottom: 0;
}

// Increase the font weight for error messages to make them more visible
.invalid-feedback {
  font-weight: 600;
}

/*!
 * Make new styled versions of the standard cards
 */
.card-primary {
  @include card-variant($card-primary-border, $card-primary-text, $card-primary-heading-bg, $card-primary-border);
}

.card-success {
  @include card-variant($card-success-border, $card-success-text, $card-success-heading-bg, $card-success-border);
}

.card-info {
  @include card-variant($card-info-border, $card-info-text, $card-info-heading-bg, $card-info-border);
}

.card-warning {
  @include card-variant($card-warning-border, $card-warning-text, $card-warning-heading-bg, $card-warning-border);
}

.card-danger {
  @include card-variant($card-danger-border, $card-danger-text, $card-danger-heading-bg, $card-danger-border);
}

.modal-header-danger {
  color: $card-danger-text;
  background-color: $card-danger-heading-bg;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  font-weight: 600;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $black;
}

.dropdown-item.active,
.dropdown-item:active {
  font-weight: 500;
}

// Ensure disabled buttons look good and disabled (i.e. greyed out)
.btn.disabled {
  background: $gray-300;
  border: $gray-300;
  pointer-events: none;
}

h1 {
  font-family: Biennale;
}

.accordion.er-accordion {
  .card-inactive {
    border: 1px dashed $border-color !important;
  }

  .card {
    @include border-radius($card-border-radius);
  }

  > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }

  > .card:not(:first-of-type) {
    .card-header:first-child {
      border-radius: 0;
    }
  }

  > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.list-group-header {
  position: relative;
  display: block;
  margin-bottom: -1px;
  background-color: $table-head-bg;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  letter-spacing: 0.08em;
  color: $table-head-color;
  border-bottom-width: $table-border-width;
  font-size: $font-size-xs;
}

.header.bg-dark .header-body {
  border-bottom-color: $dark;
}

a .card {
  border: $card-border-width solid $card-border-color !important;

  &:hover {
    background-color: $gray-200;
    @include transition();
  }
}

.bg-card-info-heading {
  background-color: $card-info-heading-bg !important;
}

.custom-control-input {
  &:focus:not(:checked) ~ .custom-control-label::before {
    border-width: 1px;
  }
}
