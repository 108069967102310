.er-bulk-actions-context {
  .er-pagination-info {
    display: inline-block;
  }
  .er-bulk-actions-dropdown {
    display: none;
  }
  .er-bulk-actions-message {
    display: none;
  }

  &.selected {
    .er-pagination-info {
      display: none;
    }
    .er-bulk-actions-dropdown {
      display: inline-block;
    }
    .er-bulk-actions-message {
      display: inline;
    }
  }
}
