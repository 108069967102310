#er-chargebee-error {
  display: none;
}

#er-pd-btn-coupon-code {
  //font-size: $font-size-base - 3;
}

#cb-card-element {
  display: block;
  width: 100%;
  height: $input-height;
  //padding: $input-padding-y $input-padding-x; // the Bootstrap default
  padding: 0.55rem $input-padding-x; // Fixed to look better with Chargebee component
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &.is-invalid {
    border: 1px solid $form-feedback-invalid-color;

    & ~ .invalid-feedback {
      display: block;
    }
  }

  &.CbHosted--focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-box-shadow, $input-focus-box-shadow;

    &.CbHosted--invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
    }
  }
}

#cb-card-errors {
  color: $form-feedback-invalid-color;
  margin-top: $form-feedback-margin-top;
  font-size: $form-feedback-font-size;
  font-weight: 600;
  width: 100%;
  display: block;
}
